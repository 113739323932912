<template>
	<div class="container">
		<div id="posts">
			<div class="row">
				<div class="col-md-12 col-xs-12">
					<div class="blog-container">
						<div class="blog-posts">
							<article v-if="about" class="post">
								<div class="post-content">
									<h3 class="title">About Me</h3>
									<div class="the-content">
										<p v-if="about.imageUrl" class="nick-photo">
											<img :src="about.imageUrl" alt="Nick Yurov's photo." title="Nick Yurov's photo" />
										</p>
										<div v-html="about.html"></div>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		about() {
			return this.$store.getters.about;
		},
	},
}
</script>

<style lang="less">
.the-content {
	.nick-photo {
		float: left;
		margin: 0 25px 15px 0;
		@media screen and (max-width: 767px) {
			text-align: center;
			float: none;
			margin: 0 auto 20px;
		}
		> img {
			width: 200px;
		}
	}
	p {
		> span {
			font-style: italic;
			font-weight: bold;
		}
	}
}
</style>