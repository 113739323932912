<template>
	<section class="portfolio-section">
		<div class="container">
			<h2 class="title">Recent Works.</h2>
			<div id="portfolio" class="portfolio fade-in">
				<div class="controls">
					<ul>
						<li>
							<a href="#" :class="{ active: currentCategory === 'all' }" @click.prevent="filter('all')">
								All
							</a>
						</li>
						<li v-for="cat in categories" :key="cat.value">
							<a href="#" :class="{ active: currentCategory === cat.value }" @click.prevent="filter(cat.value)">
								{{ cat.label }}
							</a>
						</li>
					</ul>
				</div>
				<transition-group name="gallery" class="grids" tag="div">
					<router-link
						v-for="i in images"
						:key="i.id"
						v-show="i.show"
						:to="i.url"
						class="grid-item"
					>
						<div class="media image" :style="{ backgroundImage: `url('${i.mainImageUrl}')` }">
							<img :src="i.mainImageUrl" :alt="i.name" />
						</div>
						<div class="caption">
							<div class="caption-inner">
								<h2 class="title">{{ i.name }}</h2>
								<ul class="cat">
									<li>
										<a href="#">{{ i.categoryName }}</a>
									</li>
								</ul>
							</div>
						</div>
					</router-link>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			images: [],
			currentCategory: 'all',
		};
	},
	computed: {
		storeImages() {
			return this.$store.getters.images;
		},
		categories() {
			return this.$store.getters.categories;
		},
	},
	watch: {
		storeImages: {
			handler(images) {
				if (images) {
					this.images = images;
				}
			},
			immediate: true,
		},
	},
	methods: {
		filter(category) {
			this.currentCategory = category;
			this.filteredImages = this.images.map((image) => {
				image.show = (category === 'all' || image.category === category);
			});
		},
	},
};
</script>

<style lang="less" scoped>
.gallery-enter,
.gallery-leave-to {
    opacity: 0;
  	transform: translateY(30px);
}
.gallery-leave-active {
  	position: absolute;
}
.grid-item {
	transition: all 0.3s;
}
</style>
