<template>
	<div id="main-content">
		<app-header />
		<div class="header-hide"></div>
		<keep-alive>
			<router-view />
		</keep-alive>
		<app-footer />
	</div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
	components: {
		appHeader: Header,
		appFooter: Footer,
	},
	created() {
		this.$store.dispatch('loadImages');
		this.$store.dispatch('loadAbout');
	},
};
</script>

<style lang="less">
@import '@/assets/less/styles.less';

html {
	overflow: -moz-scrollbars-vertical; 
	overflow-y: scroll;
}
.portfolio-section {
	padding-top: 70px;
}
</style>