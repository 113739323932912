<template>
	<div class="container">
		<div id="posts">
			<div class="row">
				<div class="col-md-12 col-xs-12">
					<div class="blog-container">
						<div class="blog-posts">
							<article class="post">
								<div v-if="image" class="post-content">
									<h3 class="title">{{ image.name }}</h3>
									<div class="the-content">
										<p>{{ image.description }}</p>
										<div class="project-attributes">
											<table>
												<tbody>
													<tr>
														<td class="name">Year:</td>
														<td class="value">{{ image.year }}</td>
													</tr>
													<tr>
														<td class="name">Size:</td>
														<td class="value">{{ image.size }}</td>
													</tr>
													<tr>
														<td class="name">Location:</td>
														<td class="value">{{ image.current_location }}</td>
													</tr>
													<tr>
														<td class="name">Category:</td>
														<td class="value">{{ image.categoryName }}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="images-project">
											<div class="images-wrap">
												<div
													v-for="(url, i) in urls"
													:key="i"
													class="image-item"
												>
													<a
														:href="url"
														@click.prevent="index = i"
													>
														<img :src="url" :alt="image.name + '.'" />
													</a>
												</div>
											</div>
											<div class="navigation">
												<router-link :to="image.prevImage.url" :title="image.prevImage.name" class="kd-btn">Previous Picture</router-link>
												<router-link to="/" title="Go to Gallery" class="kd-btn">Home</router-link>
												<router-link :to="image.nextImage.url" :title="image.nextImage.name" class="kd-btn">Next Picture</router-link>
											</div>
										</div>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CoolLightBox 
      		:items="urls" 
      		:index="index"
      		:useZoomBar="true"
      		@close="index = null"
		/>
	</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
	components: {
		CoolLightBox,
	},
	data() {
		return {
			index: null,
			urls: [],
		};
	},
	computed: {
		id() {
			return this.$route.params.id;
		},
		image() {
			return this.$store.getters.imageById(this.id);
		},
	},
	watch: {
		image: {
			handler(image) {
				if (image) {
					this.urls = image.imagesUrls;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less" scoped>
td.name {
	min-width: 100px;
}
.image-item {
	text-align: center;
}
.images-wrap {
	@media screen and (min-width: 768px) {
		min-height: 500px;
	}
}
.navigation {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media screen and (max-width: 767px) {
		flex-direction: column;
		text-align: center;
	}
	&:after {
		content: none;
	}
}
</style>