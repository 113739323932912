import Vue from 'vue';
import Vuex from 'vuex';
import axios from './axios';
import Image from './models/Image';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		images: [],
		categories: [],
		about: {
			imageUrl: null,
			html: '',
		},
	},
	getters: {
		images(state) {
			return state.images;
		},
		imageById: (state) => (id) => {
			return state.images.find(image => image.id === id);
		},
		categories(state) {
			return state.categories;
		},
		categoryName: (state) => (value) => {
			return state.categories.find(category => category.value === value).label;
		},
		about(state) {
			return state.about;
		}
	},
	mutations: {
		SET_IMAGES(state, images) {
			state.images = images;
		},
		SET_CATEGORIES(state, categories) {
			state.categories = categories;
		},
		SET_ABOUT(state, data) {
			state.about = data;
		}
	},
	actions: {
		loadImages({ commit }) {
			axios.get('collections/get/gallery')
			.then(r => {
				let images = [];
				const lastIndex = r.data.entries.length - 1;

				r.data.entries.forEach((imageData, i) => {
					const image = new Image(imageData);
					image.prevId = i > 0 ? r.data.entries[i - 1]._id : r.data.entries[lastIndex]._id;
					image.nextId = i < lastIndex ? r.data.entries[i + 1]._id : r.data.entries[0]._id;

					images.push(image);
				});
				
				commit('SET_IMAGES', images.sort((a, b) => a.o > b.o));
				commit('SET_CATEGORIES', r.data.fields.category.options.options);
			})
			.catch(e => {
				console.log(e);
			});
		},
		loadAbout({ commit }) {
			axios.get('singletons/get/about')
			.then(r => {
				commit('SET_ABOUT', {
					imageUrl: process.env.VUE_APP_SERVER_BASE_URL + r.data.image.path,
					html: r.data.description,
				});
			})
			.catch(e => {
				console.log(e);
			});
		},
	},
});
