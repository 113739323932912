import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
import About from '@/views/About';
import Contact from '@/views/Contact';
import Picture from '@/views/Picture';
import ErrorPage from '@/views/404';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/about',
		name: 'about',
		component: About,
	},
	{
		path: '/contact',
		name: 'contact',
		component: Contact,
	},
	{
		path: '/pictures/:id',
		name: 'pictures',
		component: Picture,
	},
	{
		path: '/error',
		name: 'error',
		component: ErrorPage,
	},
	{
		path: '*',
		name: 'redirect',
		component: ErrorPage,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
