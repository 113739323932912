import store from '@/store';

export default class Image {

	show = true;
	prevId = null;
	nextId = null;

	constructor(image) {
		for (var prop in image)
			this[prop] = image[prop];
	}

	get categoryName() {
		const category = store.getters.categories.find(cat => cat.value === this.category);
		
		return category.label || 'Uncategorized';
	}

	get url() {
		return process.env.BASE_URL + 'pictures/' + this.id;
	}

	get mainImageUrl() {
		return process.env.VUE_APP_SERVER_BASE_URL + this.images[0].path;
	}

	get imagesUrls() {
		return this.images.map(image => process.env.VUE_APP_SERVER_BASE_URL + image.path);
	}

	get id() {
		return this._id;
	}

	get order() {
		return this._o;
	}

	get hasImages() {
		return this.images.length > 0;
	}

	get prevImage() {
		return store.getters.imageById(this.prevId);
	}

	get nextImage() {
		return store.getters.imageById(this.nextId);
	}

}