<template>
	<div class="container">
		<div id="posts">
			<div class="row">
				<div class="col-md-12 col-xs-12">
					<div class="blog-container">
						<div class="blog-posts">
							<article class="post">
								<div class="post-content">
									<h3 class="title">Contact</h3>
									<div class="the-content">
										<p class="text-center">My contact information</p>
										<div class="contact-info">
											<div class="row">
												<div class="col-md-4">
													<a href="tel:+380936569003" class="item">
														<div class="item-icon">
															<i class="fa fa-phone"></i>
														</div>
														<div class="item-content">+38 (093) 656 90-03</div>
													</a>
												</div>
												<div class="col-md-4">
													<a href="mailto:jurovnik@ukr.net" class="item">
														<div class="item-icon">
															<i class="fa fa-envelope-o"></i>
														</div>
														<div class="item-content">jurovnik@ukr.net</div>
													</a>
												</div>
												<div class="col-md-4">
													<a href="https://www.facebook.com/yurovteacher" class="item" target="_blank">
														<div class="item-icon">
															<i class="fa fa-facebook"></i>
														</div>
														<div class="item-content">Art Teacher in Kyiv</div>
													</a>
												</div>
											</div>
										</div>
										<!-- <form action="#" class="contact mt30">
											<div class="contact-item">
												<input id="name" type="text" placeholder="Name *" />
											</div>
											<div class="contact-item">
												<input id="email" type="text" placeholder="Email *" />
											</div>
											<div class="contact-item">
												<input id="website" type="text" placeholder="Subject *" />
											</div>
											<div class="contact-item field-full">
												<textarea placeholder="Write your message here"></textarea>
											</div>
											<div class="contact-item form-submit">
												<input type="submit" value="Send Message" />
											</div>
										</form> -->
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	
}
</script>

<style lang="less" scoped>
.item {
	display: block;
	text-align: center;
}
</style>